@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
}
html {
  overflow-x: hidden;
}
body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  margin-top: 20px;
}

.swal-title {
  color: #000 !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}
div:where(.swal2-container) .swal2-html-container {
  text-transform: capitalize !important;
}
.swal-confirm-btn {
  color: #fff !important;
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  padding: 11px 16px !important;
  border-radius: 8px !important;
  border: 1px solid #000 !important;
  background: #3e8dff !important;
  box-shadow: 2px 2px 0px 0px #000 !important;
}

.slick-prev,
.slick-next {
  z-index: -100;
}

.__floater__open {
  filter: none !important;
}
