.mb-4.manage-option {
  display: flex;
  justify-content: space-evenly;
}
.form-check-input {
  margin-top: 2px;
}
.img-container {
  display: flex;
  justify-content: center;
}
th.content-image-video {
  font-weight: 400;
  font-size: 15px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  gap: 4px;
}

.grid label input {
  margin: 0px 6px;
  vertical-align: middle;
}

.grid label span {
  vertical-align: middle;
}

.custom-question {
  width: 110px;
}
.custom-width-next {
  display: flex;
  justify-content: space-between;
}
.custom-width-next button {
  width: 200px;
}
.back-pre {
  border: 1px solid;
  width: 100px;
  background: #1f4168;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
}
.back-pre img {
  height: 25px;
}
