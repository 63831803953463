#sidebar {
  z-index: 1000;
  position: fixed;
  left: 200px;
  width: 250px;
  height: 100%;
  margin-left: -200px;
  background: #1f4168;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#content {
  position: absolute;
  right: 0;
  width: calc(100% - 250px);
  min-height: calc(100vh - 70px);
  background: #fcfaf8;
  padding: 1.5rem 1rem;
}
#sidebar button {
  color: white;
  font-size: 20px;
  font-weight: 600;
}
.searchbox {
  width: 20%;
  float: right;
}