.login-part {
  /* background-image: url("/public/admin/Group\ 40735.png");
  background-repeat: no-repeat;
  background-size: 100% 68%; */
  padding: 82px;
}
.authincation-content {
  /* background: #F9F9FB;   */
  height: auto;
}
.login {
  display: flex;
  justify-content: center;
}
.login-card {
  width: 600px;
  flex-shrink: 0;
}
.login-card .card {
  border-radius: 14px;
  background: var(--white, #fff);
  /* box-shadow: 0px 0px 40px 0px rgba(245, 134, 52, 0.04); */
  border: none;
  padding: 40px 15px 12px 15px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.login-card .card-body {
  padding-top: 40px;
}
.login-header {
  background-color: transparent;
  /* padding: 0.75rem 1.25rem; */
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.form-login-button button {
  background: #f58634;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
  padding: 13px 22px;
  transition: all 0.3s ease;
  width: 100%;
}
.login-card label {
  color: #1f4168;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.login-card input::placeholder {
  color: #b8b8b8;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
i.fa.fa-eye {
  width: 10px;
  float: right;
  position: relative;
  right: 20px;
  top: -32px;
  cursor: pointer;
  color: #b7b7b7;
  font-weight: 100 !important;
  font-size: 14px;
}
i.fa.fa-eye-slash {
  width: 10px;
  float: right;
  position: relative;
  right: 20px;
  top: -32px;
  cursor: pointer;
}
.login-card input[type="text"] {
  outline: none;
  padding: 12px 16px;
  resize: none;
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid #bcbcbc;
}
.login-card input[type="password"] {
  outline: none;
  padding: 12px 16px;
  resize: none;
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid #bcbcbc;
}
.login-card input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
.checkbox-control label {
  color: #b8b8b8;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-left: 5px;
}
.forgot-style {
  text-decoration: none;
  color: #1f4168;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@media (max-width: 600px) {
  .login-card {
    width: auto;
  }
  .authincation-content {
    /* background: #F9F9FB;   */
    height: auto;
  }
}
@media (min-width: 650px) and (max-width: 991px) {
  .authincation-content {
    /* background: #F9F9FB;   */
    height: 100vh;
  }
}
@media (min-width: 992px) and (max-width: 1300px) {
  .authincation-content {
    /* background: #F9F9FB;   */
    height: auto;
  }
  .login-part {
    padding: 50px;
  }
}
@media (min-width: 1320px) and (max-width: 1500px) {
  .authincation-content {
    /* background: #F9F9FB;   */
    height: 100vh;
  }
  .login-part {
    padding: 50px;
  }
}
@media (min-width: 1550px) and (max-width: 7000px) {
  .authincation-content {
    /* background: #F9F9FB;  
    height: 100vh; */
  }
}
button.login_with_gmail {
  background: #fff;
  border: 2px solid #eee;
  padding: 5px 20px;
  box-shadow: rgba(58, 72, 80, 0.2) 0px 7px 25px 0px;
  border-radius: 3px;
}
.login_with_gmail img {
  height: 30px;
  margin-right: 6px;
}
